<script>
import PageHeader from "@/components/page-header";
import { ApiClientSelectedPlatform } from "@/common/http-common"
import { BredcrumpManagerEncode, BredcrumpManagerDecode } from "@/common/bredcrumb-common"
import { showNotificationFromServer, showNotificationWithText } from "@/common/notification-common"
export default {
  components: {
    PageHeader
  },
  data() {
    return {
      title: this.$t('holidays.holidays'),
      holidays: [],
      pagination: {
        total: 0,
        totalPages: 0,
        page: 1,
        pageSize: 10,
        nextPages: [],
        backPages: []
      }

    };
  },
  watch: {
    $route (to,from){
      if (to.name == "holiday" && from.name == "platform/holiday")
      window.location.reload(true);
    }
  },
  methods: {
    getData: function (page, size) {
      const self = this;
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
      var rs = JSON.parse(atob(self.$route.query.data))
      ApiClientSelectedPlatform(rs.platformId).get("/v1/admin/calendar/list?page=" + page + "&size=" + size).then(function (response) {
        self.holidays = response.data.items
        self.pagination.total = response.data.total
        self.pagination.totalPages = response.data.totalPages
        self.pagination.page = page
        self.pagination.pageSize = size

        self.pagination.nextPages = self.getNextTwoPages()
        self.pagination.backPages = self.getPreviousTwoPages()

        if (0 == parseInt(response.data.totalPages)) {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        } else {
          if (page > parseInt(response.data.totalPages)) {
            self.pagination.page = parseInt(self.pagination.totalPages)
            self.pagination.pageSize = parseInt(self.$route.query.size)
            self.getData(self.pagination.page, self.pagination.pageSize)
          } else {
            document.getElementById("preloader").style.display = "none";
            document.getElementById("status").style.display = "none";
          }
        }


        BredcrumpManagerEncode(self, { path: self.$route.path, title: 'holidays.holidays', page: self.pagination.page, size: self.pagination.pageSize })
      }).catch(
        function (error) {
          if (error) {
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.code) {
                  showNotificationFromServer(self, error.response.data.code)
                } else {
                  showNotificationWithText(self, "error.server.internalServer")
                }
              } else {
                showNotificationWithText(self, "error.server.internalServer")
              }
            } else {
              showNotificationWithText(self, "error.server.internalServer")
            }
          } else {
            showNotificationWithText(self, "error.server.internalServer")
          }
        }
      )
    },
    getPreviousTwoPages() {
      const currentPage = this.pagination.page;
      let previousTwoPages = [];
      for (let i = currentPage - 1; i >= 1 && i >= currentPage - 2; i--) {
        previousTwoPages.unshift(i);
      }
      return previousTwoPages;
    },
    getNextTwoPages() {
      const currentPage = this.pagination.page;
      const totalPages = this.pagination.totalPages;

      // Calculate the next two pages
      let nextTwoPages = [];
      for (let i = currentPage + 1; i <= totalPages && i <= currentPage + 2; i++) {
        nextTwoPages.push(i);
      }

      return nextTwoPages;
    },
    configureDataNextModule:function(id){
      if (this.$route.query.data){
        var rs = JSON.parse(atob(this.$route.query.data))
        rs.platformId = id
        return btoa(JSON.stringify(rs))
      }   
    },
    configureLinkNextModule:function(url){
        if (this.$route.path.includes("platform/detail/")){
          return "/platform/detail/"+url
        }else {
          return url
        }
    }
  },
  mounted() {
    if (this.$route.query.data) {
      var data = BredcrumpManagerDecode(this)
      if (data) {
        this.pagination.page = parseInt(data.page)
        this.pagination.pageSize = parseInt(data.size)
        this.getData(this.pagination.page, this.pagination.pageSize)
      } else {
        this.getData(this.pagination.page, this.pagination.pageSize)
      }
    } else {
      this.getData(this.pagination.page, this.pagination.pageSize)
    }
  }
};
</script>
<template>
 <div class="row">
        <div class="col-xl-12">
        <PageHeader :title="title"/>
      </div>
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ this.$t('holidays.holidays') }}</h4>
              <p class="card-title-desc">
                <router-link
                              :to="{ path: configureLinkNextModule('holiday/create'), query: { data: configureDataNextModule(null)} }">{{ $t("holidays.create") }}</router-link>
              </p>

              <div class="table-responsive">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th> {{ this.$t("holidays.list.holiday") }}
                      </th>
                      <th>{{ this.$t("holidays.list.date") }}
                      </th>
                      <th>{{ this.$t("holidays.list.religion") }}</th>
                      <th>{{ this.$t("holidays.list.workingDay") }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in holidays" :key="index">
                      <td>{{ item.id }}</td>
                      <td>{{ item.title }}</td>
                      <td>{{ $moment(item.date).format("DD/MM/YYYY") }}</td>
                      <td>{{ item.religion.title }}</td>
                      <td>{{ $t("question." + item.is_working_day) }}</td>
                      <td><router-link
                              :to="{ path: configureLinkNextModule('holiday/update'), query: { data: configureDataNextModule(null)} }">{{ $t("holidays.update") }}</router-link></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row mt-4" v-if="pagination.totalPages != 1 && holidays.length != 0">
                <div class="col-sm-6">
                  <div>
                    <p class="mb-sm-0 mt-2">
                      {{ this.$t("pagination.page") }}
                      <span class="font-weight-bold">{{ pagination.page }}</span> {{ this.$t("pagination.from") }}
                      <span class="font-weight-bold">{{ pagination.totalPages }}</span>
                    </p>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="float-sm-right">
                    <ul class="pagination pagination-rounded mb-sm-0">
                      <li class="page-item" v-bind:class="(pagination.page == 1) ? 'disabled' : ''">
                        <a href="#" class="page-link"
                          @click="getData(parseInt(pagination.page) - 1, pagination.pageSize)">
                          <i class="mdi mdi-chevron-left"></i>
                        </a>
                      </li>
                      <li class="page-item" v-for="item in pagination.backPages" :key="item">
                        <a href="#" class="page-link" @click="getData(item, pagination.pageSize)">{{ item }}</a>
                      </li>
                      <li class="page-item active">
                        <a href="#" class="page-link">{{ pagination.page }}</a>
                      </li>
                      <li class="page-item" v-for="item in pagination.nextPages" :key="item">
                        <a href="#" class="page-link" @click="getData(item, pagination.pageSize)">{{ item }}</a>
                      </li>
                      <li class="page-item" v-bind:class="(pagination.page == pagination.totalPages) ? 'disabled' : ''">
                        <a href="#" class="page-link" @click="getData(parseIn(pagination.page) + 1, pagination.pageSize)">
                          <i class="mdi mdi-chevron-right"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>